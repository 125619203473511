<template>
  <default-layout>
    <div class="p-4">
      <forget-pass></forget-pass>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import ForgetPass from '@/components/auth/forget-pass';
import { mapState } from 'vuex';

import DefaultHeadMixin from '@/mixins/defaultHead';

export default {
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    ForgetPass,
  },
  computed: {
    ...mapState({}),
  },
  methods: {},
  mounted() {},
};
</script>
